import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import titleMixin from './mixins/titleChanger.js'
import VueSweetalert2 from 'vue-sweetalert2';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'sweetalert2/dist/sweetalert2.min.css';


const options = {
  confirmButtonColor: '#3eb074',
};
Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2, options);
Vue.use(Vuelidate)
Vue.mixin(titleMixin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
new Vue({
  render: h => h(App),
}).$mount('#app')
