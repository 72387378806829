<template>
  <b-modal
    id="pin"
    title="Wprowadź PIN aby uzyskać dostęp"
    centered
    hide-header-close
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    ok-title="Przejdź dalej"
    header-class="pin-title"
  >
  <div class='input-zone'>
      <input @keyup="move(1)" v-model.number="firstNum" ref="firstNum" type="number" min="0" max='9'  size="1" class="single-pin"/>
      <input @keyup="move(2)" v-model.number="secondNum" ref="secondNum" type="number" min="0" max='9'  size="1" class="single-pin"/>
      <input @keyup="move(3)" v-model.number="thirdNum" ref="thirdNum" type="number" min="0" max='9'  size="1" class="single-pin"/>
      <input @keyup="validate()" ref="fourthNum" v-model.number="fourthNum" type="number" min="0" max='9'  size="1" class="single-pin"/>
  </div>
  <div class="button-zone" @click="reset()">
    <h3>Reset<b-icon icon="x-circle" class="ic"></b-icon></h3>
  </div>
  
  </b-modal>
</template>

<script>
export default {
  data(){
    return{
      firstNum: null,
      secondNum: null,
      thirdNum: null,
      fourthNum: null,
      pinValid: null,
    }
  },
  mounted(){
    this.$root.$emit('bv::show::modal', 'pin', '#input-1')
  },
    methods: {
      move(num){
        if(num === 1){
        this.$refs.secondNum.focus()
        }
        if(num === 2){
        this.$refs.thirdNum.focus()
        }
        if(num === 3){
        this.$refs.fourthNum.focus()
        }
      },
      reset(){
        this.firstNum = null;
        this.secondNum = null;
        this.thirdNum = null;
        this.fourthNum = null;
        this.$refs.firstNum.focus()
      },
      validate(){
        if(this.checkPIN){
        this.pinValid = true;
        this.$emit('validPin')
        } else {
        this.pinValid = false;
        }
      }
    },
    computed: {
      checkPIN () {
          const input = [this.firstNum, this.secondNum, this.thirdNum, this.fourthNum].join("");
          const password = 2554;
          return password === parseInt(input) ? true : false;
      }
    }
}
</script>


<style>
#pin.modal.fade {
  background-color: #3eb073bb !important;
  backdrop-filter: blur(3px);
}
.pin-title{
   
    display: block;
    justify-content: center !important;
    border-bottom: 1px solid black;;
}
.input-zone{
    display: flex;
    align-content: center;
    justify-content: center;
}
.single-pin {
    min-width: 45px;
    height: 60px;
    margin: 10px 14px;
}
.single-pin {
    font-size: 2em
}
.button-zone{
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
.ic{
  margin: 0 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-zone input[type=number] {
  -moz-appearance: textfield;
  text-align: center;
}
</style>