<template>
  <div id="app">
    <home v-if="!isAuth" />
    <b-container v-if="isAuth">
      <b-row align-h="center">
        <b-col cols="12">
          <img id="splendidlogo" src="./assets/logocz.png" />
        </b-col>
        <b-col xs="12" md="8"
          ><form-wizard
            nextButtonText="Dalej"
            backButtonText="Powrót"
            title="Portal rozliczeń kierowców"
            subtitle="Prosimy o uważne wypełnienie "
            finishButtonText="Prześlij"
            color="#3eb074"
            @on-complete="sendForm"
            ref="formWizard"
          >
            <tab-content
              title="Dane podstawowe"
              class="formularz"
              :beforeChange="validateTab"
            >
              <b-alert show variant="success" class="month-info"
                >Rozliczasz się za miesiąc: {{ form.month }}</b-alert
              >

              <b-form-group
                id="name"
                label="Imię i nazwisko / full name"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  :class="{ 'is-invalid': $v.form.name.$error }"
                  @blur="$v.form.name.$touch()"
                  type="text"
                  trim
                ></b-form-input>
                <div class="invalid-feedback">Proszę wprowadź imię.</div>
              </b-form-group>
              <b-form-group
                id="name"
                label="Adres e-mail / e-mail adress"
                label-for="input-2"
              >
                <b-form-input
                  type="email"
                  id="input-2"
                  :class="{ 'is-invalid': $v.form.email.$error }"
                  @blur="$v.form.email.$touch()"
                  v-model="form.email"
                  trim
                ></b-form-input>
                <div class="invalid-feedback">
                  Nie wygląda to na poprawny adres e-mail.
                </div>
              </b-form-group>
              <b-form-group
                label="Czy posiadasz kartę służbową Circle-K?"
              >
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="circleK"
                  :options="doubleOption"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group
                :label="`Chce kartę Multisport na miesiąc (140 PLN): Maj`"
              >
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="multisport"
                  :options="doubleOption"
                  name="radios-btn-default"
                  buttons
                />
              </b-form-group>
              <b-form-group
                :label="`Chce prywatną opiekę zdrowotną Medicover na miesiąc (za dodatkową opłatą): Maj`"
              >
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="privateCare"
                  :options="doubleOption"
                  name="radios-btn-default"
                  buttons
                />
              </b-form-group>
              <b-form-group
                :label="`Chce dodatkowe ubezpiecznie na życie na miesiąc (za dodatkową opłatą): Maj`"
              >
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="healthInsurance"
                  :options="doubleOption"
                  name="radios-btn-default"
                  buttons
                />
              </b-form-group>
              <b-form-group
                label="Czy pracujesz poza Splendid Fleet / posiadasz status studenta?"
              >
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="anotherJob"
                  :options="doubleOption"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
                <b-form-group
                  label="Status zatrudnienia poza Splendid Fleet"
                  id="status"
                  v-if="anotherJob"
                >
                  <b-form-radio-group
                    v-model="selected"
                    :options="options"
                    name="radio-inline"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form-group>
              <!-- <b-form-group
                label="Chce rozliczyć inny miesiąc"
              >
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="anotherMonth"
                  :options="doubleOption"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
              </b-form-group> -->

              <b-form-group
                id="month"
                v-if="anotherMonth"
                label="Inny miesiąc rozliczeniowy / Other billing month"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  :options="getMonth"
                  v-model="form.month"
                  :class="{ 'is-invalid': $v.form.month.$error }"
                  @blur="$v.form.month.$touch()"
                ></b-form-select>

                <div class="invalid-feedback">
                  Proszę wybrać miesiąc rozliczeniowy.
                </div>
              </b-form-group>
            </tab-content>
            <tab-content
              title="Okresy rozliczeniowe"
              :beforeChange="validateSecond"
            >
              <div id="center">
                <!-- okres pierwszy -->
                <h4 class="title">
                  <b-badge class="przypinka"
                    >I tydzień rozliczeniowy<br />
                    {{ this.getPeriods.period1.start }} -
                    {{ this.getPeriods.period1.end }}</b-badge
                  >
                </h4>
                <b-form-group id="uber-1" label="Uber" label-for="uber1">
                  <b-form-input
                    type="number"
                    id="uber1"
                    :class="{ 'is-invalid': $v.form.uber1.$error }"
                    @blur="$v.form.uber1.$touch()"
                    v-model="form.uber1"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group id="bolt-1" label="Bolt" label-for="bolt1">
                  <b-form-input
                    type="number"
                    id="bolt1"
                    :class="{ 'is-invalid': $v.form.bolt1.$error }"
                    @blur="$v.form.bolt1.$touch()"
                    v-model="form.bolt1"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group id="free-1" label="Free Now" label-for="free1">
                  <b-form-input
                    type="number"
                    id="free1"
                    :class="{ 'is-invalid': $v.form.free1.$error }"
                    @blur="$v.form.free1.$touch()"
                    v-model="form.free1"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group
                  id="money1-1"
                  label="Gotówka / Taken money"
                  label-for="money1"
                >
                  <b-form-input
                    type="number"
                    id="money1"
                    :class="{ 'is-invalid': $v.form.money1.$error }"
                    @blur="$v.form.money1.$touch()"
                    v-model="form.money1"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <!-- okres drugi -->
                <h4 class="title">
                  <b-badge class="przypinka"
                    >II tydzień rozliczeniowy<br />
                    {{ this.getPeriods.period2.start }} -
                    {{ this.getPeriods.period2.end }}
                  </b-badge>
                </h4>
                <b-form-group id="uber-2" label="Uber" label-for="uber2">
                  <b-form-input
                    type="number"
                    id="uber2"
                    :class="{ 'is-invalid': $v.form.uber2.$error }"
                    @blur="$v.form.uber2.$touch()"
                    v-model="form.uber2"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group id="bolt-2" label="Bolt" label-for="bolt2">
                  <b-form-input
                    type="number"
                    id="bolt2"
                    :class="{ 'is-invalid': $v.form.bolt2.$error }"
                    @blur="$v.form.bolt2.$touch()"
                    v-model="form.bolt2"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group id="free-2" label="Free Now" label-for="free2">
                  <b-form-input
                    type="number"
                    id="free2"
                    :class="{ 'is-invalid': $v.form.free2.$error }"
                    @blur="$v.form.free2.$touch()"
                    v-model="form.free2"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group
                  id="money1-2"
                  label="Gotówka / Taken money"
                  label-for="money2"
                >
                  <b-form-input
                    type="number"
                    id="money2"
                    :class="{ 'is-invalid': $v.form.money1.$error }"
                    @blur="$v.form.money2.$touch()"
                    v-model="form.money2"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <!-- okres trzeci -->
                <h4 class="title">
                  <b-badge class="przypinka"
                    >III tydzień rozliczeniowy<br />
                    {{ this.getPeriods.period3.start }} -
                    {{ this.getPeriods.period3.end }}</b-badge
                  >
                </h4>
                <b-form-group id="uber-3" label="Uber" label-for="uber3">
                  <b-form-input
                    type="number"
                    id="uber3"
                    :class="{ 'is-invalid': $v.form.uber3.$error }"
                    @blur="$v.form.uber3.$touch()"
                    v-model="form.uber3"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group id="bolt-3" label="Bolt" label-for="bolt3">
                  <b-form-input
                    type="number"
                    id="bolt3"
                    :class="{ 'is-invalid': $v.form.bolt3.$error }"
                    @blur="$v.form.bolt3.$touch()"
                    v-model="form.bolt3"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group id="free-3" label="Free Now" label-for="free3">
                  <b-form-input
                    type="number"
                    id="free3"
                    :class="{ 'is-invalid': $v.form.free3.$error }"
                    @blur="$v.form.free3.$touch()"
                    v-model="form.free3"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group
                  id="money1-3"
                  label="Gotówka / Taken money"
                  label-for="money3"
                >
                  <b-form-input
                    type="number"
                    id="money3"
                    :class="{ 'is-invalid': $v.form.money3.$error }"
                    @blur="$v.form.money3.$touch()"
                    v-model="form.money3"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <!-- okres czwarty -->
                <h4 class="title">
                  <b-badge class="przypinka"
                    >IV tydzień rozliczeniowy<br />
                    {{ this.getPeriods.period4.start }} -
                    {{ this.getPeriods.period4.end }}</b-badge
                  >
                </h4>
                <b-form-group id="uber-4" label="Uber" label-for="uber4">
                  <b-form-input
                    type="number"
                    id="uber4"
                    :class="{ 'is-invalid': $v.form.uber4.$error }"
                    @blur="$v.form.uber4.$touch()"
                    v-model="form.uber4"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group id="bolt-4" label="Bolt" label-for="bolt4">
                  <b-form-input
                    type="number"
                    id="bolt4"
                    :class="{ 'is-invalid': $v.form.bolt4.$error }"
                    @blur="$v.form.bolt4.$touch()"
                    v-model="form.bolt4"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group id="free-4" label="Free Now" label-for="free4">
                  <b-form-input
                    type="number"
                    id="free4"
                    :class="{ 'is-invalid': $v.form.free4.$error }"
                    @blur="$v.form.free4.$touch()"
                    v-model="form.free4"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <b-form-group
                  id="money1-4"
                  label="Gotówka / Taken money"
                  label-for="money4"
                >
                  <b-form-input
                    type="number"
                    id="money4"
                    :class="{ 'is-invalid': $v.form.money4.$error }"
                    @blur="$v.form.money4.$touch()"
                    v-model="form.money4"
                    :formatter="fixedNumbers"
                    lazy-formatter
                    number
                  ></b-form-input>
                  <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                </b-form-group>
                <!-- okre piąty -->
                <div v-if="this.getWeekLength.periodLength === 5">
                  <h4 class="title">
                    <b-badge class="przypinka"
                      >V tydzień rozliczeniowy<br />
                      {{ this.getPeriods.period5.start }} -
                      {{ this.getPeriods.period5.end }}</b-badge
                    >
                  </h4>
                  <b-form-group id="uber-5" label="Uber" label-for="uber5">
                    <b-form-input
                      type="number"
                      id="uber5"
                      :class="{ 'is-invalid': $v.form.uber5.$error }"
                      @blur="$v.form.uber5.$touch()"
                      v-model="form.uber5"
                      :formatter="fixedNumbers"
                      lazy-formatter
                      number
                    ></b-form-input>
                    <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                  </b-form-group>
                  <b-form-group id="bolt-5" label="Bolt" label-for="bolt5">
                    <b-form-input
                      type="number"
                      id="bolt5"
                      :class="{ 'is-invalid': $v.form.bolt5.$error }"
                      @blur="$v.form.bolt5.$touch()"
                      v-model="form.bolt5"
                      :formatter="fixedNumbers"
                      lazy-formatter
                      number
                    ></b-form-input>
                    <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                  </b-form-group>
                  <b-form-group id="free-5" label="Free Now" label-for="free5">
                    <b-form-input
                      type="number"
                      id="free5"
                      :class="{ 'is-invalid': $v.form.free4.$error }"
                      @blur="$v.form.free5.$touch()"
                      v-model="form.free5"
                      :formatter="fixedNumbers"
                      lazy-formatter
                      number
                    ></b-form-input>
                    <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                  </b-form-group>
                  <b-form-group
                    id="money1-5"
                    label="Gotówka / Taken money"
                    label-for="money5"
                  >
                    <b-form-input
                      type="number"
                      id="money5"
                      :class="{ 'is-invalid': $v.form.money4.$error }"
                      @blur="$v.form.money5.$touch()"
                      v-model="form.money5"
                      :formatter="fixedNumbers"
                      lazy-formatter
                      number
                    ></b-form-input>
                    <div class="invalid-feedback">Wprowadź poprawną kwotę.</div>
                  </b-form-group>
                </div>
              </div>
            </tab-content>
            <tab-content
              title="Dane rozliczeniowe"
              :beforeChange="validateThird"
            >
              <b-form-group
                id="ilosc-zmian"
                label="Ilość zmian"
                label-for="ilosc-zmian-1"
              >
                <b-form-input
                  type="number"
                  id="ilosc-zmian-1"
                  :class="{ 'is-invalid': $v.form.iloscZmian.$error }"
                  @blur="$v.form.iloscZmian.$touch()"
                  v-model="form.iloscZmian"
                  step="1.00"
                  min="0"
                  max="31"
                  number
                  :formatter="fixedNum"
                  lazy-formatter
                ></b-form-input>
                <b-row v-if="form.iloscZmian > 0">
                  <b-col cols="12">
                    <h4 class="title">
                      Podstawa rozliczeń <br /><b-badge class="przypinka"
                        >{{ podstawaRozliczen }} PLN</b-badge
                      >
                    </h4>
                  </b-col>
                  <b-col cols="12">
                    <h4 class="title">
                      Suma obrotu <br /><b-badge class="przypinka"
                        >{{ obrotTotal }} PLN</b-badge
                      >
                    </h4>
                  </b-col>
                  <b-col cols="12">
                    <h4 class="title">
                      Średnia za zmianę<br /><b-badge class="przypinka"
                        >{{ sredniaZmiana }} PLN</b-badge
                      >
                    </h4>
                  </b-col>
                  <b-col cols="12">
                    <h4 class="title">
                      Gotówka <br /><b-badge class="przypinka"
                        >{{ moneyTotal }} PLN</b-badge
                      >
                    </h4>
                  </b-col>
                  <b-col cols="12">
                    <h4 class="title">
                      Opłata za rozliczenie <br /><b-badge class="przypinka"
                        >{{ form.oplataRozliczenie * drivingPeriod}} PLN</b-badge
                      >
                    </h4>
                  </b-col>
                </b-row>
                <div class="invalid-feedback">
                  Ilość zmian musi być większa od 0 a mniejsza niż ilość dni w
                  miesiącu.
                </div>
              </b-form-group>
            </tab-content>
            <tab-content title="Dane dodatkowe">
              <b-form-group
                id="suma-paliwa"
                label="Wprowadź koszt paliwa"
                label-for="suma-paliwa-1"
                description="Jeżeli nie znasz kosztu paliwa, wprowadź 0"
              >
                <b-form-input
                  type="number"
                  id="suma-paliwa-1"
                  :class="{ 'is-invalid': $v.form.sumaPaliwa.$error }"
                  @blur="$v.form.sumaPaliwa.$touch()"
                  v-model="form.sumaPaliwa"
                  number
                  :formatter="fixedNumbers"
                  lazy-formatter
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="suma-bonus"
                label="Wprowadź sumę bonusów"
                label-for="suma-bonus-1"
                description="Tylko i wyłącznie kwota bonusów przyznanych przez Splendid Fleet, jeżeli nie uzyskałeś żadnego wpisz 0."
              >
                <b-form-input
                  type="number"
                  id="suma-bonus-1"
                  :class="{ 'is-invalid': $v.form.kwotaBonus.$error }"
                  @blur="$v.form.kwotaBonus.$touch()"
                  v-model="form.kwotaBonus"
                  number
                  :formatter="fixedNum"
                  lazy-formatter
                ></b-form-input>
              </b-form-group>
              <b-container class="info">
                <b-row v-if="form.sumaPaliwa >= 0" align-v="center">
                  <b-col cols="12">
                    <b-alert show variant="danger" v-if="form.sumaPaliwa === 0"
                      >Od poniższej kwoty należy odliczyć koszt paliwa.</b-alert
                    >
                    <div>
                      <h3>Do wypłaty</h3>
                      <b-badge class="przypinka-info"
                        ><span class="do-wyplaty"
                          ><h4>{{ doWyplaty }} PLN</h4></span
                        ></b-badge
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </tab-content>
          </form-wizard></b-col
        >
      </b-row>
    </b-container>
    <pin-checker v-if="!isAuth" @validPin="auth"/>
    <ankieta v-on:ankieta="showConfirm" :month="form.month" />
    <div class="author-details">
      made by <a href="https://rightcloud.pl">rightcloud</a>
    </div>
    <div class="ankieta" @click="showModal">
      ankieta satysfakcji
    </div>
  </div>
</template>

<script>
import Ankieta from "./components/EmpHappy.vue";
import pinChecker from "./components/PinChecker.vue";
import home from "./components/Home.vue"
import { FormWizard, TabContent } from "vue-form-wizard";
import { required, email, between } from "vuelidate/lib/validators";
import moment from "moment";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
export default {
  name: "App",
  components: {
    FormWizard,
    TabContent,
    Ankieta,
    pinChecker,
    home,
  },
  title() {
    return "Splendid Fleet - Portal Rozliczeń Kierowców";
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        month: "Marzec",
        uber1: null,
        bolt1: null,
        free1: null,
        money1: null,
        uber2: null,
        bolt2: null,
        free2: null,
        money2: null,
        uber3: null,
        bolt3: null,
        free3: null,
        money3: null,
        uber4: null,
        bolt4: null,
        free4: null,
        money4: null,
        uber5: null,
        bolt5: null,
        free5: null,
        money5: null,
        iloscZmian: null,
        sumaPaliwa: null,
        kwotaBonus: null,
        oplataRozliczenie: 25,
      },
      isAuth: false,
      isLoading: false,
      weekNumber: 4,
      anotherJob: false,
      anotherMonth: false,
      // new options
      circleK: false,
      multisport: false,
      privateCare: false,
      healthInsurance: false,
      // end of options
      selected: null,
      options: [
        { text: "Student dzienny", value: "student dzienny" },
        { text: "Student Zaoczny", value: "student zaoczny" },
        { text: "Emeryt", value: "emeryt" },
        { text: "Rencista", value: "rencista" },
        { text: "Zatrudniony na etat", value: "etat" },
      ],
      doubleOption: [
        { text: "Tak", value: true },
        { text: "Nie", value: false },
      ],
    };
  },
  validations: {
    form: {
      name: { required },
      email: { email, required },
      month: { required },
      uber1: { required, between: between(0, 4500) },
      free1: { required, between: between(0, 4500) },
      bolt1: { required, between: between(0, 4500) },
      money1: { required, between: between(0, 4500) },
      uber2: { required, between: between(0, 4500) },
      free2: { required, between: between(0, 4500) },
      bolt2: { required, between: between(0, 4500) },
      money2: { required, between: between(0, 4500) },
      uber3: { required, between: between(0, 4500) },
      free3: { required, between: between(0, 4500) },
      bolt3: { required, between: between(0, 4500) },
      money3: { required, between: between(0, 4500) },
      uber4: { required, between: between(0, 4500) },
      free4: { required, between: between(0, 4500) },
      bolt4: { required, between: between(0, 4500) },
      money4: { required, between: between(0, 4500) },
      uber5: { between: between(0, 4500) },
      free5: { between: between(0, 4500) },
      bolt5: { between: between(0, 4500) },
      money5: { between: between(0, 4500) },
      iloscZmian: { between: between(1, 31) },
      sumaPaliwa: { between: between(0, 4500) },
      kwotaBonus: { between: between(0, 1000) },
    },
  },
  methods: {
    validateTab() {
      this.$v.$touch();
      if (this.$v.form.name.$error || this.$v.form.email.$error) {
        this.$swal("Prosimy o prawidłowe wypełnienie wszystkich pól.");
        return null;
      } else {
        this.$v.$reset();
        return true;
      }
    },
    auth(){
      this.isAuth = true;
    },
    showModal() {
      this.$bvModal.show("ankieta");
    },
    validateSecond() {
      this.$v.$touch();
      if (
        this.$v.form.uber1.$error ||
        this.$v.form.bolt1.$error ||
        this.$v.form.free1.$error ||
        this.$v.form.money1.$error ||
        this.$v.form.uber2.$error ||
        this.$v.form.bolt2.$error ||
        this.$v.form.free2.$error ||
        this.$v.form.money2.$error ||
        this.$v.form.uber3.$error ||
        this.$v.form.bolt3.$error ||
        this.$v.form.free3.$error ||
        this.$v.form.money3.$error ||
        this.$v.form.uber4.$error ||
        this.$v.form.bolt4.$error ||
        this.$v.form.free4.$error ||
        this.$v.form.money4.$error ||
        this.$v.form.uber5.$error ||
        this.$v.form.bolt5.$error ||
        this.$v.form.free5.$error ||
        this.$v.form.money5.$error
      ) {
        this.showAlert();
        return null;
      } else {
        this.$v.$reset();
        return true;
      }
    },
    validateThird() {
      this.$v.$touch();
      if (this.$v.form.iloscZmian.$error) {
        this.$swal("Proszę wprowadzić ilość zmian.");
        return null;
      } else {
        this.$v.$reset();
        return true;
      }
    },
    showAlert() {
      this.$swal(
        "Prosimy o wypełnienie wszystkich pól - jeżeli nie pracowałeś w danym okresie rozliczeniowym wpisz 0."
      );
    },
    showConfirm() {
      this.$swal("Przesłano pomyślnie!");
    },
    fixedNum(val) {
      let number = parseFloat(val);
      return number.toFixed(0);
    },
    fixedNumbers(val) {
      let number = parseFloat(val);
      return number > 0 ? number.toFixed(2) : 0;
    },
    async sendForm() {
      let data = {
        ...this.form,
        okres1: this.okres1,
        okres2: this.okres2,
        okres3: this.okres3,
        okres4: this.okres4,
        okres5: this.okres5,
        obrotTotal: this.obrotTotal,
        doWyplaty: this.doWyplaty,
        sredniaZmiana: this.sredniaZmiana,
        podstawaRozliczen: this.podstawaRozliczen,
        isNewShift: true,
        status: this.selected,
        periodStart: this.getWeekLength.periodStart,
        monthLenght: this.getWeekLength.periodLength,
        circlek: this.circleK,
        multisport: this.multisport,
        privateCare: this.privateCare,
        healthInsurance: this.healthInsurance,
      };
      this.$v.$touch();
      // 3z
      if (!this.isLoading && !this.$v.$invalid) {
        this.isLoading = true;
        const response = await this.axios
          .post(
            `${process.env.VUE_APP_API}/splendid-wyplaties/?token=${process.env.VUE_APP_TOKEN}`,
            data
          )
          .catch((err) => {
            console.log(err);
            this.$swal("Wystąpił błąd - skontaktuj się z administracja.");
          });
        this.isLoading = false;
        if (response.status !== 200) {
          this.$swal(
            "Wystąpił problem, prosimy spróbować później i skontaktować się z administracją."
          );
        } else {
          this.$refs.formWizard.reset();
          await this.$swal("Przesłano pomyślnie!");
          this.showModal();
        }
      } else {
        if (this.isLoading) {
          this.$swal("Formularz aktualnie jest wysyłany.");
        } else {
          this.$swal(
            "Wystąpił błąd - proszę sprawdzić wszystkie pola w formularzu."
          );
        }
      }
    },
  },
  watch: {
    anotherMonth: function (val) {
      if (!val) {
        this.form.month = "Luty";
      }
    },
  },
  computed: {
    getWeekLength() {

      if (this.form.month === "Grudzień") {
        return { periodStart: "20211129", periodLength: 4 };
      }
      if(this.form.month === "Styczeń"){
       return { periodStart: "20221227", periodLength: 5 };
      } 
      if(this.form.month === "Luty"){
       return { periodStart: "20220131", periodLength: 4 };
      }
      if(this.form.month === "Marzec"){
       return { periodStart: "20220228", periodLength: 4 };
      } else {
        return "Invalid setup";
      }
    },
    drivingPeriod(){
      let drivingWeeks = 0
      this.okres1 > 0 ? drivingWeeks += 1 : false
      this.okres2 > 0 ? drivingWeeks += 1 : false
      this.okres3 > 0 ? drivingWeeks += 1 : false
      this.okres4 > 0 ? drivingWeeks += 1 : false
      this.okres5 > 0 ? drivingWeeks += 1 : false
      return drivingWeeks
    },
    getMonth() {
      return [
        { value: "Lipiec", text: "Lipiec" },
        { value: "Sierpień", text: "Sierpień" },
        { value: "Październik", text: "Październik" },
        { value: "Marzec", text: "Marzec" },
      ];
    },
    getPeriods() {
      let period = moment(this.getWeekLength.periodStart, "YYYYMMDD");
      let period2 = period.clone().add(7, "days");
      let period3 = period.clone().add(14, "days");
      let period4 = period.clone().add(21, "days");
      let period5 = period.clone().add(28, "days");
      return {
        period1: {
          start: period.format("D[.]M[.]YYYY"),
          end: period.add(6, "days").format("D[.]M[.]YYYY"),
        },
        period2: {
          start: period2.format("D[.]M[.]YYYY"),
          end: period2.clone().add(6, "days").format("D[.]M[.]YYYY"),
        },
        period3: {
          start: period3.format("D[.]M[.]YYYY"),
          end: period3.clone().add(6, "days").format("D[.]M[.]YYYY"),
        },
        period4: {
          start: period4.format("D[.]M[.]YYYY"),
          end: period4.clone().add(6, "days").format("D[.]M[.]YYYY"),
        },
        period5: {
          start: period5.format("D[.]M[.]YYYY"),
          end: period5.clone().add(6, "days").format("D[.]M[.]YYYY"),
        },
      };
    },
    moneyTotal() {
      return (
        this.form.money1 +
        this.form.money2 +
        this.form.money3 +
        this.form.money4 +
        this.form.money5
      );
    },
    okres1() {
      let total = this.form.uber1 + this.form.bolt1 + this.form.free1;
      let fixedVal = total.toFixed(2);
      return parseFloat(fixedVal);
    },
    okres2() {
      let total = this.form.uber2 + this.form.bolt2 + this.form.free2;
      let fixedVal = total.toFixed(2);
      return parseFloat(fixedVal);
    },
    okres3() {
      let total = this.form.uber3 + this.form.bolt3 + this.form.free3;
      let fixedVal = total.toFixed(2);
      return parseFloat(fixedVal);
    },
    okres4() {
      let total = this.form.uber4 + this.form.bolt4 + this.form.free4;
      let fixedVal = total.toFixed(2);
      return parseFloat(fixedVal);
    },
    okres5() {
      let total = this.form.uber5 + this.form.bolt5 + this.form.free5;
      let fixedVal = total.toFixed(2);
      return parseFloat(fixedVal);
    },
    obrotTotal() {
      let total =
        this.okres1 + this.okres2 + this.okres3 + this.okres4 + this.okres5;
      let fixedVal = total.toFixed(2);
      return parseFloat(fixedVal);
    },
    doWyplaty() {
      let total =
        this.podstawaRozliczen -
        (this.form.oplataRozliczenie * this.drivingPeriod + this.form.sumaPaliwa) +
        this.form.kwotaBonus;
      let fixedVal = total.toFixed(2);
      return parseFloat(fixedVal);
    },
    sredniaZmiana() {
      let total = this.obrotTotal / this.form.iloscZmian;
      let fixedVal = total.toFixed(2);
      return parseFloat(fixedVal);
    },
    podstawaRozliczen() {
      let podstawa = 0;
            // pierwszy próg
      if (this.sredniaZmiana <= 259.99) {
        let total = this.obrotTotal - this.form.iloscZmian * 130;
        let fixedVal = total.toFixed(2);
        podstawa = parseFloat(fixedVal);
      }
      // drugi próg
      if (this.sredniaZmiana <= 299.99 && this.sredniaZmiana >= 260.00) {
        let total = this.obrotTotal * 0.55;
        let fixedVal = total.toFixed(2);
        podstawa = parseFloat(fixedVal);
      }
      // trzeci
      if (this.sredniaZmiana <= 359.99 && this.sredniaZmiana >= 300.00) {
        let total = this.obrotTotal * 0.61;
        let fixedVal = total.toFixed(2);
        podstawa = parseFloat(fixedVal);
      }
      // czwarty
      if (this.sredniaZmiana <= 419.99 && this.sredniaZmiana >= 360.00) {
        let total = this.obrotTotal * 0.63;
        let fixedVal = total.toFixed(2);
        podstawa = parseFloat(fixedVal);
      }
      // piąty
      if (this.sredniaZmiana >= 420) {
        let total = this.obrotTotal * 0.65;
        let fixedVal = total.toFixed(2);
        podstawa = parseFloat(fixedVal);
      }
      return podstawa;
    },
  },
};
</script>

<style>
#app {
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin-bottom: 2em;
}

#input-3 {
  display: block;
  width: 100%;
  height: 35px;
}
.formularz {
  text-align: center;
}
#input-1,
#input-2 {
  text-align: center;
}
.przypinka {
  background-color: #3eb074;
  width: 100%;
  margin-bottom: 0.5em;
}
#center input {
  text-align: center;
}
.przypinka-info {
  background-color: #3eb074;
  width: 60%;
}
.title {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
#ilosc-zmian input {
  text-align: center;
}
#suma-paliwa input {
  text-align: center;
}
#suma-bonus input {
  text-align: center;
}
.do-wyplaty {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.info {
  margin-top: 2em;
  margin-bottom: 2em;
}
#splendidlogo {
  max-width: 340px;
}
@media only screen and (max-width: 600px) {
  .vue-form-wizard .wizard-btn {
    min-width: 100px !important;
  }
}
#status {
  margin-top: 15px;
  margin-bottom: 15px;
}
#status span {
  margin-left: 1em;
}
#status .custom-control-inline {
  padding: 0 5px;
  margin: 10px;
}
.month-info.alert-success {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #3eb074;
  color: white;
}
#btn-radios-1 input {
  display: none;
}
.btn.active {
  background-color: #3eb074 !important;
  border: 0;
}
.btn.btn-secondary {
  background-color: #97dfb8;
  border: 0;
}
.author-details {
  position: fixed;
  right: 16px;
  bottom: 8px;
  font-size: 0.6em;
  color: #ccc;
}
.author-details a {
  text-decoration: none;
  letter-spacing: 2px;
  color: rgb(187, 187, 187);
}
.ankieta {
  text-decoration: none;
  cursor: pointer;
  position: fixed;
  left: 16px;
  bottom: 8px;
  letter-spacing: 2px;
  font-size: 0.7em;
  color: rgb(187, 187, 187);
}
.author-details a:hover {
  color: #ffaa17;
}
</style>
