<template>
  <b-modal
    id="ankieta"
    ref="ankieta"
    title="Anonimowa ankieta satysfakcji"
    hide-header-close
    ok-title="Prześlij"
    cancel-title="Anuluj"
    ok-variant=""
    ok-only
    @ok="sendForm"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Jak długo pracujesz z nami?"
        label-for="howLong"
        label-align="center"
      >
        <b-form-radio-group
          class="howLong star"
          v-model="ankieta.howLong"
          :options="workOption"
          :class="{ 'is-invalid': $v.ankieta.howLong.$error }"
          @click="$v.ankieta.howLong.$touch()"
          buttons
        >

        </b-form-radio-group>
                <b-form-invalid-feedback :state="!$v.ankieta.howLong.$error">Proszę wybrać ilość miesięcy.</b-form-invalid-feedback>
      </b-form-group>
      <!-- <b-form-group
        label="Oceń pracę koordynatorów systemu zmianowego"
        label-for="ocena3z"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.ocena3z"
          variant="success"
          class="mb-2 stars"
          no-border
          id="ocena3z"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.ocena3z.$error }"
          @click="$v.ankieta.ocena3z.$touch()"
        />
      </b-form-group> -->
      <b-form-group
        label="Oceń stan techniczny pojazdów"
        label-for="stanPojazdu"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.stanPojazdu"
          variant="success"
          class="mb-2 stars"
          no-border
          id="stanPojazdu"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.stanPojazdu.$error }"
          @click="$v.ankieta.stanPojazdu.$touch()"
        />
      </b-form-group>
      <b-form-group
        label="Oceń system wynagrodzeń"
        label-for="systemWynagrodzen"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.systemWynagrodzen"
          variant="success"
          class="mb-2 stars"
          no-border
          id="systemWynagrodzen"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.systemWynagrodzen.$error }"
          @click="$v.ankieta.systemWynagrodzen.$touch()"
        />
      </b-form-group>
      <b-form-group
        label="Oceń pomoc podczas problemu z pojazdem"
        label-for="problemPojazd"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.problemPojazd"
          variant="success"
          class="mb-2 stars"
          no-border
          id="problemPojazd"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.problemPojazd.$error }"
          @click="$v.ankieta.problemPojazd.$touch()"
        />
      </b-form-group>
      <b-form-group
        label="Czy warunki rozliczenia są dla Ciebie jasne i przejrzyste?"
        label-for="problemRozliczenie"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.problemRozliczenie"
          variant="success"
          class="mb-2 stars"
          no-border
          id="problemRozliczenie"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.problemRozliczenie.$error }"
          @click="$v.ankieta.problemRozliczenie.$touch()"
        />
      </b-form-group>
      <!-- <b-form-group
        label="Oceń pomoc podczas problemu z grafikiem"
        label-for="problemGrafik"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.problemGrafik"
          variant="success"
          class="mb-2 stars"
          no-border
          id="problemGrafik"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.problemGrafik.$error }"
          @click="$v.ankieta.groblemGrafik.$touch()"
        />
      </b-form-group> -->
      <b-form-group
        label="Oceń proces rekrutacji"
        label-for="procesRekrutacja"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.procesRekrutacja"
          variant="success"
          class="mb-2 stars"
          no-border
          id="procesRekrutacja"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.procesRekrutacja.$error }"
          @click="$v.ankieta.procesRekrutacja.$touch()"
        />
      </b-form-group>
      <b-form-group
        label="Jak oceniasz pracę biura? Czy pracownicy są pomocni?"
        label-for="ocenBiuro"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.ocenBiuro"
          variant="success"
          class="mb-2 stars"
          no-border
          id="ocenBiuro"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.ocenBiuro.$error }"
          @click="$v.ankieta.ocenBiuro.$touch()"
        />
      </b-form-group>
      <b-form-group
        label="Oceń poziom zadowolenia z pracy"
        label-for="zadowolenie"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.zadowolenie"
          variant="success"
          class="mb-2 stars"
          no-border
          id="zadowolenie"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.zadowolenie.$error }"
          @click="$v.ankieta.zadowolenie.$touch()"
        />
      </b-form-group>

      <!-- nowe pytania -->
      <b-form-group
        label="Jak oceniasz komunikację SMS?"
        label-for="komunikacja"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.komunikacjaSMS"
          variant="success"
          class="mb-2 stars"
          stars="5"
          no-border
          id="komunikacja"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.komunikacjaSMS.$error }"
          @click="$v.ankieta.komunikacjaSMS.$touch()"
        />
      </b-form-group>
      <b-form-group
        label="Czy komunikaty SMS są zrozumiałe?"
        label-for="zrozumienie"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.zrozumienieSMS"
          variant="success"
          class="mb-2 stars"
          stars="5"
          no-border
          id="zrozumienie"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.zrozumienieSMS.$error }"
          @click="$v.ankieta.zrozumienieSMS.$touch()"
        />
      </b-form-group>
            <b-form-group
        label="Czy poleciłbyś znajomemu pracę w Splendid Fleet?"
        label-for="polecenie"
        label-align="center"
      >
        <b-form-rating
          v-model="ankieta.polecenie"
          variant="success"
          class="mb-2 stars"
          stars="10"
          no-border
          id="polecenie"
          show-value
          show-value-max
          :class="{ 'is-invalid': $v.ankieta.polecenie.$error }"
          @click="$v.ankieta.polecenie.$touch()"
        />
      </b-form-group>
      <b-form-group
        label="Jakie informacje chciałbyś otrzymywać regularnie drogą SMS?"
        label-for="dodatkoweInformacje"
        label-align="center"
      >
        <b-form-textarea
          id="dodatkoweInformacje"
          class="stars"
          v-model="ankieta.dodatkoweInformacje"
          placeholder="Np. informacje o wydarzeniach, wypłatach, ważnych zmianach - bądź wolnych miejscach na grafiku."
          rows="3"
          max="6"
          :class="{ 'is-invalid': $v.ankieta.dodatkoweInformacje.$error }"
          @blur="$v.ankieta.dodatkoweInformacje.$touch()"
        />
      </b-form-group>
      <b-form-group
        label="Jakich informacji Ci brakuje w komunikacji z biurem?"
        label-for="brakiBiuro"
        label-align="center"
      >
        <b-form-textarea
          id="brakiBiuro"
          class="stars"
          v-model="ankieta.brakiBiuro"
          rows="3"
          max="6"
          :class="{ 'is-invalid': $v.ankieta.brakiBiuro.$error }"
          @blur="$v.ankieta.brakiBiuro.$touch()"
        />
      </b-form-group>
      <b-form-group
        label="Twoje sugestie"
        label-for="sugestie"
        label-align="center"
      >
        <b-form-textarea
          id="sugestie"
          class="stars"
          v-model="ankieta.sugestie"
          placeholder="Podpowiedz nam, czy jest coś co możemy poprawić."
          rows="3"
          max="6"
          :class="{ 'is-invalid': $v.ankieta.sugestie.$error }"
          @blur="$v.ankieta.sugestie.$touch()"
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  props: ['month'],
  data() {
    return {
      ankieta: {
        dlugoscPracy: null,
        ocena3z: null,
        stanPojazdu: null,
        systemWynagrodzen: null,
        problemPojazd: null,
        problemRozliczenie: null,
        problemGrafik: null,
        procesRekrutacja: null,
        ocenBiuro: null,
        zadowolenie: null,
        polecenie: null,
        sugestie: null,
        howLong: null,
        komunikacjaSMS: null,
        zrozumienieSMS: null,
        brakiBiuro: null,
        dodatkoweInformacje: null
      },
      isSending: false,
      workOption: [
        { text: "0-3 miesięcy", value: "3" },
        { text: "3-6 miesięcy", value: "6" },
        { text: "Więcej niż 6 miesięcy", value: "7" },
      ],
    };
  },
  validations: {
    ankieta: {
      stanPojazdu: { required },
      systemWynagrodzen: { required },
      problemPojazd: { required },
      problemRozliczenie: { required },
      procesRekrutacja: { required },
      ocenBiuro: { required },
      zadowolenie: { required },
      polecenie: { required },
      sugestie: { required },
      howLong: { required },
      komunikacjaSMS: {required},
      zrozumienieSMS: {required},
      brakiBiuro: {required},
      dodatkoweInformacje: {required}
    },
  },
  methods: {
    async sendForm(event) {
      this.$v.ankieta.$touch();
      if (!this.$v.ankieta.$invalid) {
        this.isLoading = true;
        let data = {...this.ankieta, month: this.month}
        const response = await this.axios
          .post(
            `${process.env.VUE_APP_API}/ankieta-splendids/?token=${process.env.VUE_APP_TOKEN}`,
            data
          )
          .catch((err) => {
            console.log(err);
            this.$swal("Wystąpił błąd - skontaktuj się z administracja.");
          });
        this.isLoading = false;
        if (response.status !== 200) {
          this.$swal(
            "Wystąpił problem, prosimy spróbować później i skontaktować się z administracją."
          );
        } else {
        this.$emit("ankieta");
        }
      } else {
        event.preventDefault();
      }
    },
  },
};
</script>

<style>
#howLong {
  text-align: center;
}

.stars {
  margin-top: 1em;
}
.stars::placeholder {
  text-align: center;
}
.howLong input {
  visibility: hidden !important;
}
</style>
